"use strict";

import {on,addClass} from "@elements/dom-utils";
import axios from "axios";
import {onFind} from "@elements/init-modules-in-scope";
import lightGallery from "lightgallery";

export function init () {
    onFind('.js-lightbox-dynamic', function (lightboxDynItem) {
        const lightboxClickHandler = (evt, lightboxDynItem) => {
            evt.preventDefault();
            evt.stopImmediatePropagation();
            lightboxDynItem.style.pointerEvents = "none";

            let url = lightboxDynItem.getAttribute('href');

            let index = parseInt(lightboxDynItem.getAttribute('data-dynamic-lightbox-index')) || 0;

            let request = axios({
                method: 'get',
                url: url,
                headers: {'X-Requested-With': 'XMLHttpRequest'}
            });

            request.then(response => response.data)
                .then(result => {
                if (result.success) {
                    let gallery = lightGallery(lightboxDynItem, {
                            dynamic: true,
                            dynamicEl: result.content,
                            download:false,
                            index: index,
                            licenseKey: '7AF43751-F35F4C70-A604AE1C-12C521AE',
                        });
                    gallery.openGallery(index);
                    lightboxDynItem.style.pointerEvents = "auto";
                }

                }).catch(e => {
                console.warn(e);
            });
        };

        addClass('lightbox-is-init',lightboxDynItem);
        on('click', (evt) => lightboxClickHandler(evt, lightboxDynItem), lightboxDynItem);
    });
}