import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as dynamicLightbox from './scripts/dynamic-lightbox';
dynamicLightbox.init();

import * as smoothScroll from "@elements/smooth-scroll";
smoothScroll.init();

import * as gradientAnimation from './scripts/gradient-animation';
gradientAnimation.init();

import * as heroAnimation from './scripts/hero-animation';
heroAnimation.init();

import * as contentVisibility from './scripts/content-visibility';
contentVisibility.init();

import Collapse from 'bootstrap/js/dist/collapse';

